input, textarea, select, option {
  outline: none;
  font-family: "Segoe WPC", "Segoe UI", Helvetica, Arial, "Arial Unicode MS", sans-serif;
  border-radius: 0;
  margin: 0;
}

label {
  cursor: pointer;
}

.form-error {
  color: #ff0000;
}

.form-success {
  color: #72bd08;
}

.custom-form-field {
  margin-bottom: 21px;
}

.custom-form-field-label {
  margin-bottom: 3px;
  font-size: 14px;
}

.custom-textfield,
.custom-textfield-small,
.custom-textarea {
  font-size: 16px;
  line-height: 21.2pt;
  padding: 1px 3px;
  margin: 0;
  border: 1px solid #ccc;
}

.chzn-container {
  min-height: 27px;
  height: auto !important;
}

.custom-textfield {
  width: 350px;
  height: 32px;
}

.custom-textfield-small {
  width: 168px;
}

.custom-select,
.custom-select-small {
  background: #fff;
  -webkit-appearance: button;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  height: 43px;
  font-size: 21px;
  padding: 0;
  width: 358px;
  border: 1px solid #ccc;
}

.custom-select-multiple {
  width: 325px;
}

.custom-select-small {
  width: 165px;
}

.custom-textarea {
  width: 500px;
  min-height: 175px;
  padding: 7px;
}

/*
.form-field-placeholder {
	color: #a9a9a9;
}
*/

:-ms-input-placeholder {
  color: #a9a9a9;
}

::-webkit-input-placeholder {
  color: #a9a9a9;
}

:-moz-placeholder { /* Firefox 18- */
  color: #a9a9a9;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #a9a9a9;
}

.chzn-container-active .chzn-single,
.chzn-container-active .chzn-drop,
.chzn-container-active .chzn-choices,
.custom-select:focus,
.custom-textfield:focus,
.custom-textfield-small:focus,
.custom-textarea:focus,
div.token-input-dropdown {
  border-color: #0069B4 !important;
}

.fileupload-click-input {
  position: absolute;
  left: -9999999px;
}

.fileupload-click-button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 115px;
}

.chzn-container {
  vertical-align: top;
}

.custom-select-chosen-large .chzn-choices {
  min-height: 34px;
}

.custom-select-chosen-large .chzn-container-single .chzn-single {
  height: 34px;
  line-height: 34px;
  font-size: 18px;
}

.custom-select-chosen-large .chzn-container-single .chzn-single span {
  margin-right: 32px;
}

.custom-select-chosen-large .chzn-container-single .chzn-single div {
  width: 32px;
}

@media (max-width: 768px) {
  .google-map {
    width: 100% !important;
  }

  .search-form-field {
    width: 100% !important;
  }

  .custom-textfield-small {
    width: 46% !important;
  }

  .custom-textfield {
    width: 100% !important;
  }

  .chzn-container {
    width: 100% !important;
  }

  #googlemap_address {
    width: 60% !important;
  }

  .custom-textarea {
    width: 100% !important;
  }

  .custom-textfield-autocomplete-btn {
    position: absolute;
    right: 0;
  }

  select {
    width: 100% !important;
  }

  .custom-textfield-autocomplete-btn {
    position: absolute;
    right: 0;
  }
}
